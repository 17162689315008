
import { Trans } from 'i18n'
import styled, { useTheme } from 'styled-components'
import {
  CV_HOME_PAGE,CV_LAYOUT_BOX,
  CV_LOGO,CV_MENU_UL,CV_MENU_LI,CV_BUTTON,
  CV_P1,CV_IFRAME,CV_MASK,CV_BUTTON_ORANGE,
  CV_ITEM_BOX, CV_ITEM,CV_TITLE,CV_VALUE,CV_LINE1,
  CV_CONTENT,CV_CONTENT_ITEM,CV_H_TITLE,CV_P_CTX,CV_H_BUTTON,CV_H_I,
  CV_EXTRA,CV_EXTRA_TITLE,CV_EXTRA_P,CV_I_YELLOW,CV_I_BLACK,
  CV_TEXT_COPYRIGHT,CV_ICON_UL,CV_ICON_LI,
} from './styled'
import { useNavigate } from 'react-router-dom'
import logoImg from './assets/logo.svg'
import Spline from '@splinetool/react-spline';

export default function Home() {
  const navigate = useNavigate()
  const toNextPage = (key: any) => {
    navigate(`/${key}`)
  }

  return <CV_HOME_PAGE>
    <CV_LAYOUT_BOX className='header'>
      <CV_LOGO src={logoImg} alt="logo"></CV_LOGO>
      <CV_MENU_UL>
        <CV_MENU_LI className='active' onClick={() => toNextPage('swap')}>Swap</CV_MENU_LI>
        <CV_MENU_LI onClick={() => toNextPage('pool')}>Pools</CV_MENU_LI>
        <CV_MENU_LI onClick={() => toNextPage('liquidity')}>Liquidity</CV_MENU_LI>
      </CV_MENU_UL>
      <CV_BUTTON className='isNull'>Connect wallet</CV_BUTTON>
    </CV_LAYOUT_BOX>
    <CV_LAYOUT_BOX>
      <CV_P1>Welcome to NINJAX</CV_P1>
    </CV_LAYOUT_BOX>
    <CV_LAYOUT_BOX className='relative'>
      <Spline
        style={{ height: '497px' }}
        className='spline-canvas'
        scene="https://prod.spline.design/wjyxjpdoeXjzxCOG/scene.splinecode"
      />
      <CV_MASK>
        <CV_BUTTON_ORANGE onClick={() => toNextPage('swap')}>Get Started</CV_BUTTON_ORANGE>
      </CV_MASK>
    </CV_LAYOUT_BOX>
    {/* <CV_LAYOUT_BOX>
      <CV_ITEM_BOX>
        <CV_ITEM>
          <CV_TITLE>TVL</CV_TITLE>
          <CV_VALUE>$1,018,404,140.16</CV_VALUE>
        </CV_ITEM>
        <CV_LINE1>&nbsp;</CV_LINE1>
        <CV_ITEM>
          <CV_TITLE>TVL24H Volume</CV_TITLE>
          <CV_VALUE>$1,403,809,350.72</CV_VALUE>
        </CV_ITEM>
      </CV_ITEM_BOX>
    </CV_LAYOUT_BOX> */}
    <CV_LAYOUT_BOX className='flex-center'>
      <CV_CONTENT>
        <CV_CONTENT_ITEM className="pool">
          <CV_H_TITLE>V3 POOL</CV_H_TITLE>
          <CV_P_CTX>Concentrated Liquidity Leads to <br/><CV_H_I>Higher Earnings</CV_H_I></CV_P_CTX>
          <CV_H_BUTTON onClick={() => toNextPage('pool')}>Add Liquidity</CV_H_BUTTON>
        </CV_CONTENT_ITEM>
        <CV_CONTENT_ITEM className="swap">
          <CV_H_TITLE>SWAP</CV_H_TITLE>
          <CV_P_CTX>Swap with <CV_H_I>lower</CV_H_I> slippage</CV_P_CTX>
          <CV_H_BUTTON onClick={() => toNextPage('swap')}>Swap</CV_H_BUTTON>
        </CV_CONTENT_ITEM>
      </CV_CONTENT>
    </CV_LAYOUT_BOX>
    <CV_LAYOUT_BOX>
      <CV_EXTRA>
        <CV_EXTRA_TITLE>Extra Yields</CV_EXTRA_TITLE>
        <CV_EXTRA_P>Growing with the <CV_I_YELLOW  onClick={() => {
          window.open('https://www.tabichain.com/')
        }}>Tabi Ecosystem</CV_I_YELLOW>, Helping Users Earn <CV_I_BLACK>Extra Rewards</CV_I_BLACK></CV_EXTRA_P>
      </CV_EXTRA>
    </CV_LAYOUT_BOX>
    <CV_LAYOUT_BOX className='footer'>
      <CV_TEXT_COPYRIGHT>Copyright ©2024 NINJAX Inc. All rights reserved.</CV_TEXT_COPYRIGHT>
      <CV_ICON_UL>
        <CV_ICON_LI className="twitter" onClick={() => {
          window.open('https://x.com/NinjaX_Finance')
        }}></CV_ICON_LI>
        {/* <CV_ICON_LI className="telegram"></CV_ICON_LI> */}
        <CV_ICON_LI className="discord"  onClick={() => {
          window.open('https://discord.com/invite/hvgectE2kK')
        }}></CV_ICON_LI>
      </CV_ICON_UL>
    </CV_LAYOUT_BOX>
  </CV_HOME_PAGE>
}
